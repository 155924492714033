import $ from 'jquery'

export default class Num {
  constructor ($el) {
    this.DOM = {}
    this.DOM.$el = $el

    this.init()
  }

  init () {
    this.cacheSelectors()
    this.getValues()
    this.bindEvents()
    this.syncButtons()
  }

  cacheSelectors () {
    this.DOM.$buttons = this.DOM.$el.find('.num__button')
    this.DOM.$input = this.DOM.$el.find('.num__input')
  }

  getValues () {
    this.value = parseInt(this.DOM.$input.val(), 10)
    this.maxValue = this.DOM.$input.data('max') || 99
  }

  increaseValue () {
    this.value = Math.min(++this.value, this.maxValue)
    this.DOM.$input.val(this.value).trigger('change')
  }

  decreaseValue () {
    this.value = Math.max(--this.value, 0)
    this.DOM.$input.val(this.value).trigger('change')
  }

  syncButtons () {
    this.DOM.$buttons.eq(0).attr('disabled', this.value <= 1)
    this.DOM.$buttons.eq(1).attr('disabled', this.value >= this.maxValue)
  }

  bindEvents () {
    this.DOM.$buttons.eq(0).on('click', () => this.decreaseValue())
    this.DOM.$buttons.eq(1).on('click', () => this.increaseValue())
    this.DOM.$input.on('change', e => {
      this.value = parseInt(e.target.value, 10)
      this.syncButtons()
    })
  }
}
