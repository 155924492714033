import Swiper from 'swiper'
import { Navigation, Scrollbar, FreeMode } from 'swiper/modules'

if ($('.js-orderProductsSlider').length > 0) {
  new Swiper('.js-orderProductsSlider', {
    modules: [Navigation, Scrollbar, FreeMode],
    slidesPerView: 4,

    navigation: {
      prevEl: '.js-orderProductsSliderPrev',
      nextEl: '.js-orderProductsSliderNext',
    },

    scrollbar: {
      el: '.swiper-scrollbar',
    },

    freeMode: $(window).width() <= 767,

    breakpoints: {
      320: {
        slidesPerView: 'auto',
        slidesOffsetAfter: 10,
        slidesOffsetBefore: 10,
        spaceBetween: 15,
        loop: false,
      },
      768: {
        slidesPerView: 5,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        loop: true,
      },
    },
  })
}
