$(document).on('click', '.js-asidePanelButton', (e) => {
  if (e.currentTarget === 'a') {
    e.preventDefault()
  }

  e.stopPropagation()
  asidePanelShow($(e.currentTarget).attr('data-aside-panel'))
})

const asidePanelShow = (id) => {
  const currentPanel = $(
    `[data-aside-panel-id="${id}"]`,
  )

  currentPanel.addClass('is_opened')

  $('body').addClass('body-lock')
}

$(document).on('click', '.js-asidePanelClose', () => {
  asidePanelClose()
})

const asidePanelClose = () => {
  $('.aside-panel').removeClass('is_opened')

  $('body').removeClass('body-lock')
}

export { asidePanelShow, asidePanelClose }
