import $ from 'jquery'
import Autocomplete from "./autocomplete";

export default class CityAside {
    constructor($el, order) {
        this.DOM = {}
        this.DOM.$el = $el
        this.order = order

        this.init()
    }

    init() {
        this.cacheSelectors()
        this.createComponents()
    }

    cacheSelectors() {
        this.DOM.$input = this.DOM.$el.find('input[name="city"]')
    }

    createComponents () {
        let _this = this
        this.validator = this.DOM.$el.validate({
            submitHandler: (form, e) => {
                e.preventDefault()

                _this.order.DOM.$cityInput.val(this.DOM.$input.val())
                _this.order.DOM.$cityFiasIdInput.val(this.DOM.$input.closest('.js-input').find('>input[type="hidden"]').val())
                if (typeof _this.order.DOM.$addressInputs !== 'undefined'){
                    for (let i = 0; i < _this.order.DOM.$addressInputs.length; i++) {
                        _this.order.resetInput(i, _this.order.DOM.$addressInputs.eq(i))
                    }
                }

                _this.order.refresh(() => {
                    $('.aside-panel').removeClass('is_opened')
                    $('body').removeClass('body-lock')
                })
            }
        })

        this.initAutocomplete(this.DOM.$input)
    }

    initAutocomplete (input) {
        const $input = $(input)
        const $wrapper = $input.siblings('.suggestions-wrapper')
        const $idInput = this.getIdInput($input)

        try
        {
            $input.typeahead('destroy');
            $wrapper.html('');
        }
        catch (exception)
        {

        }

        const ac = new Autocomplete($input,
            {
                menu: $wrapper,
                hint: false,
                highlight: true,
                minLength: 1,
                autoselect: true,
            },
            {
                display: "value",
                templates: {
                    suggestion: data => {
                        if (data.unrestricted_value) {
                            return `<div class="tt-suggestion tt-selectable"><div>${data.unrestricted_value}</div></div>`;
                        } else {
                            return `<div class="tt-suggestion tt-selectable"><div>${data.value}</div></div>`;
                        }
                    },
                    empty: $input.attr('data-typeahead-empty') ? '<div class="tt-suggestion tt-selectable">'
                        + $input.attr('data-typeahead-empty') + '</div>' : false,
                },
            }
        )

        ac.data.clear()
        ac.data.initialize(true)

        $input.on('typeahead:select', (e, suggestion) => {
            $idInput.val(suggestion.id)
            $idInput.data('value', suggestion.value);
        })

        $input.on('typeahead:close', e => {
            if ($input.val() !== $idInput.data('value')) {
                this.resetInput($input)
            }
        })

        $input.on('focus', () => {
            if ($input.val().length) {
                $input.typeahead('close')
            }
        })
    }

    getIdInput ($input) {
        return $input.closest('.js-input').find('>input[type="hidden"]')
    }

    resetInput ($input) {
        const $idInput = this.getIdInput($input)

        $input.typeahead('val', '').trigger('input')
        $idInput.val('')
        $idInput.data('value', '')

        this.initAutocomplete($input)
    }
}