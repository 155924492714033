/* eslint-disable no-unused-expressions */
import Mustache from 'mustache'
import {asidePanelClose} from "./aside-panel";

export default class DeliveryCDEK {
  constructor($el, deliveryList, order) {
    this.DOM = {}
    this.DOM.$el = $el
    this.DOM.$checkoutDeliveryList = $('.js-checkout-delivery-list')
    this.DOM.$checkoutDeliverDetail = $('.js-checkout-delivery-detail')
    this.DOM.$pvzStepList = $('.js-deliveryCdekList')
    this.DOM.$pvzStepDetail = $('.js-deliveryCdekDetail')
    this.DOM.$returnLink = $('.js-pvzReturnLink')
    this.deliveryList = deliveryList
    this.order = order

    this.DOM.checkoutDeliveryListTemplate = $(
      '.js-checkout-delivery-list-template',
    ).html()
    this.DOM.checkoutDeliverDetailTemplate = $(
      '.js-checkout-delivery-detail-template',
    ).html()

    this.init()
  }

  init() {
    this.renderList()
    this.initHandlers()

    this.DOM.$returnLink.on('click', () => {
      this.backToList()
    })

    this.DOM.$checkoutDeliverDetail.on('click', '.js-submit', (e) => {
      let pickupPointId = $(e.currentTarget).data('id')
      this.order.DOM.$delivery.find('.js-pickup-id').val(pickupPointId)
      this.order.refresh((e) => {
        asidePanelClose()
      })
    })
  }

  renderList() {
    if (this.deliveryList) {
      this.deliveryList.forEach((element) => {
        const rendered = Mustache.render(
          this.DOM.checkoutDeliveryListTemplate,
          element,
        )

        this.DOM.$checkoutDeliveryList.append(rendered)
      })
    }
  }

  initHandlers() {
    const pvzItems = this.DOM.$checkoutDeliveryList.find('.js-pvzItem')

    if (pvzItems.length > 0) {
      pvzItems.on('click', (e) => {
        this.renderDetail($(e.currentTarget).data('id'))
      })
    }
  }

  renderDetail(id) {
    const currentDetail = this.deliveryList.filter(
      (item) => item.PICKUP_POINT_ID === id,
    )

    if (currentDetail.length) {
      this.DOM.$pvzStepList.hide()

      const rendered = Mustache.render(
        this.DOM.checkoutDeliverDetailTemplate,
        currentDetail[0],
      )

      window.setCenterPlacemark({ center: currentDetail[0].COORDINATES })
      this.DOM.$checkoutDeliverDetail.html(rendered)

      this.DOM.$pvzStepDetail.show()
    }
  }

  backToList() {
    this.DOM.$pvzStepList.show()
    this.DOM.$pvzStepDetail.hide()

    window.centerMapByClusters()
  }
}
