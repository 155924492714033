import $ from 'jquery'

export default class BasketManager {
  constructor (app) {
    this.app = app
    this.data = null
  }

  setData (data) {
    this.data = data
  }

  addBasket (offerId) {
    this.data.IN_BASKET.push(parseInt(offerId))
  }

  inBasket (offerId) {
    if (this.data) {
      return $.inArray(parseInt(offerId), this.data.IN_BASKET) !== -1
    } else {
      return false
    }
  }

  refresh (data, reloadIfOrder = false) {
    if (!data) {
      return
    }

    this.setData(data)
    if (this.app.headerBasket) {
      this.app.headerBasket.data = this.data
      this.app.headerBasket.render(true)
    }

    if (this.app.basket) {
      this.app.basket.data = this.data
      this.app.basket.render()
    }

    if (this.app.order) {
      if (reloadIfOrder) {
        window.location.reload()
      } else {
        this.app.order.data = this.data
        this.app.order.render()
        this.app.order.refresh()
      }
    }
  }
}
