/* eslint-disable */

export const renderCluster = (ref) => {
  const config = ref.config
  const MyIconClusterContentLayout = window.ymaps.templateLayoutFactory.createClass(
    `<div class="map-cluster" style="background-image: url(${config.cluster.default.icon}); --color: ${config.cluster.default.color}; --hover-color: ${config.cluster.hover.color}; "'> 
      <div class="map-cluster__content">{{ properties.geoObjects.length }}</div>
      <div class="map-cluster__bg-hover" style="background-image: url(${config.cluster.hover.icon})"></div>
    </div>`
  )

  const clusterer = new window.ymaps.Clusterer({
    clusterIcons: [
      {
        size: [40, 40],
        offset: [-20, -20],
      },
    ],
    clusterDisableClickZoom: false,
    clusterHideIconOnBalloonOpen: false,
    geoObjectHideIconOnBalloonOpen: false,
    hasBalloon: false,
    clusterIconContentLayout: MyIconClusterContentLayout,
  })

  clusterer.events.add('mouseenter', function (event) {
    const target = event.get('target')
    if (typeof target.getGeoObjects != 'undefined') {
      const element = target.getOverlaySync().getElement()
      element.querySelector('.map-cluster').classList.add('map-cluster_hover')
    }
  })

  clusterer.events.add('mouseleave', function (event) {
    const target = event.get('target')
    if (typeof target.getGeoObjects != 'undefined') {
      const element = target.getOverlaySync().getElement()
      element.querySelector('.map-cluster').classList.remove('map-cluster_hover')
    }
  })

  return clusterer
}
