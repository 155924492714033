/* eslint-disable */
import { renderMap } from './render-map'
const mapComponent = document.querySelector('#js-map')

export const initMap = ({ data, placemarkOnClick }) => {
  const config = {
    "title": "Офисы/Дилеры",
    "activeFilter": "__all__",
    "map": {
      "center": [55.76, 37.64],
      "zoom": 10,
      "maxZoom": 18,
      "minZoom": 7
    },
    "cluster": {
      "default": {
        "icon": "/local/templates/iso/iso-markup/build/images/map/cluster.svg",
        "color": "black"
      },
      "hover": {
        "icon": "/local/templates/iso/iso-markup/build/images/map/cluster-active.svg",
        "color": "red"
      }
    },
    "placemark": {
      "default": {
        "icon": "/local/templates/iso/iso-markup/build/images/map/placemark.svg"
      },
      "hover": {
        "icon": "/local/templates/iso/iso-markup/build/images/map/placemark-hover.svg"
      },
      "active": {
        "icon": "/local/templates/iso/iso-markup/build/images/map/placemark-active.svg"
      }
    }
  }

  const ref = {
    map: null,
    activeFilter: config.activeFilter,
    data: data,
    config: config,
    filters: [],
    mapElement: mapComponent,
    placemarkOnClick: placemarkOnClick,
  }

  mapComponent.innerHTML = `
      <div class="map">
        ${renderMap({ ref })}
      </div>
    `
}