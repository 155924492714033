import Swiper from 'swiper'
import { Autoplay, EffectFade } from 'swiper/modules'

if ($('.js-catalogEnteringSwiper').length > 0) {
  new Swiper('.js-catalogEnteringSwiper', {
    modules: [Autoplay, EffectFade],
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  })
}

