import Form from './form'

$('#personalForm').on('submit', (e) => {
  let informBlock = $('.js-personalFormInformSuccess')
  let submitBlock = $('.js-personalFormSubmitBlock')

  let form = new Form(e)
  form.post((response) => {
    informBlock.css('display', 'block')
    submitBlock.css('display', 'none')

    setTimeout(() => {
      informBlock.css('display', 'none')
      submitBlock.css('display', 'block')
    }, 5000)
  })
})
