$('.js-cartButton').on('click', (e) => {
  e.preventDefault()
  $('.cart-aside').addClass('is_opened')

  $('body').addClass('body-lock')
})

$('.js-cartAsideClose').on('click', () => {
  $('.cart-aside').removeClass('is_opened')

  $('body').removeClass('body-lock')
})
