import Form from './form'
import Mustache from "mustache";

export default class PhoneAuth {
  constructor() {
    this.checkCodeSelector = $('.js-auth-check-code-body')
    this.checkCodeDOM = this.checkCodeSelector.html()

    this.bindEvents()
  }

  renderFirstStep(data) {
    this.checkCodeSelector.html(Mustache.render(this.checkCodeDOM, data))
  }

  handleFirstStep(event) {
    let form = new Form(event)
    form.post((response) => {
      this.renderFirstStep(response.data)
      window.authFormOpen('login-by-code')
    })
  }

  handleSecondStep(event) {
    let form = new Form(event)
    form.post((response) => {
      window.location.href = window.location.href=form.selector.data('success-url')
    })
  }

  bindEvents() {
    $('#popupAuthPhoneForm').on('submit', (e) => this.handleFirstStep(e))
    $('#popupAuthCheckCodeForm').on('submit', (e) => this.handleSecondStep(e))
  }
}
