import $ from 'jquery'
import Bloodhound from 'corejs-typeahead/dist/bloodhound'
import 'corejs-typeahead'
import Mustache from 'mustache'

export default class Autocomplete {
  constructor($input, options = {}, datasets = {}) {
    this.DOM = {}
    this.DOM.$input = $input

    this.data = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${this.DOM.$input.attr('data-ajax-url')}&q=%QUERY`,
        wildcard: '%QUERY',
      },
    })

    this.defaultOptions = {
      highlight: true,
      minLength: 2,
    }

    this.defaultDatasets = {
      display: 'value',
      limit: 10,
      source: this.data,
      templates: {
        suggestion: (data) => Mustache.render(
          `<div class="tt-menu-item">
                <a href="{{url}}">{{value}}</a>
                <sup>{{amount}}</sup>
              </div>`,
          data,
        ),
      },
    }

    this.options = $.extend({}, this.defaultOptions, options)
    this.datasets = $.extend({}, this.defaultDatasets, datasets)

    this.init()

    return this
  }

  init() {
    this.createComponents()
  }

  createComponents() {
    this.DOM.$input.typeahead(
      this.options,
      this.datasets,
    )

    this.DOM.$input.attr('role', 'presentation')

    this.DOM.$input.on('typeahead:open typeahead:change', function () {
      const { menu } = $(this).data('tt-typeahead')

      if (menu.datasets[0].isEmpty()) {
        menu.empty()
      }
    })
  }
}
