/* eslint-disable */
import { renderPlacemarkList } from './render-placemark-list'
import { renderCluster } from './render-cluster'

export const renderMapContent = ({ref}) => {
  const map = ref.map
  const items = ref.data
  const filter = ref.activeFilter

  map.geoObjects.removeAll()

  const cluster = renderCluster(ref)
  renderPlacemarkList({cluster, items, filter, config: ref.config, ref})

  map.geoObjects.add(cluster)
}
