$('body').append(`
  <div class="page-loader js-loader">
    <div class="preloader-circle">
      <div class="preloader-circle__outer"></div>
      <div class="preloader-circle__inner"></div>
    </div>
  </div>
`)

export const showLoader = () => {
  $('.js-loader').addClass('is_visible')
}

export const hideLoader = () => {
  $('.js-loader').removeClass('is_visible')
}
