$(document).ready(() => {
  const preloader = $('.js-preloader')

  if (preloader) {
    setTimeout(() => {
      preloader.addClass('preloader_hide')
    }, 300)

    setTimeout(() => {
      preloader.remove()
    }, 1500)
  }
})
