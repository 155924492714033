/* eslint-disable */
export const renderPlacemarkList = ({cluster, items, filter, config, ref}) => {

  for (const item of items) {
    if (filter === '__all__' || item.filter === filter) {
      const myPlacemark = new window.ymaps.Placemark(item.COORDINATES, {
        hintContent: item.TITLE,
        iconContent: `
          <div class="place-mark" style="background-image: url('${config.placemark.default.icon}')" data-coordinates='${JSON.stringify(item.COORDINATES)}'>
            <div class="place-mark__hover"  style="background-image: url('${config.placemark.hover.icon}')"></div>
            <div class="place-mark__active" style="background-image: url('${config.placemark.active.icon}')"></div>
          </div>
      `,
      }, {
        hideIconOnBalloonOpen: false,
        iconLayout: 'default#imageWithContent',
        iconImageHref: '',
        iconImageSize: [23, 30],
        iconImageOffset: [11, -15],
        hasBalloon: true,
      })

      myPlacemark.events.add(['click'],  function (e) {
        if ('placemarkOnClick' in ref) {
          ref.placemarkOnClick(item)
        }
      })

      cluster.add(myPlacemark)
    }
  }
}
