const TIME = 400

$(document).on('click', '.js-tabControl', (e) => {
  const id = $(e.currentTarget).data('id')
  const content = $(`[data-tab-content="${id}"]`)

  $(e.currentTarget).addClass('is_active').siblings().removeClass('is_active')

  if (content.length > 0) {
    content.each((_, el) => {
      const isFade = $(el).data('fade')
      const speed = $(el).data('speed') || TIME
      const delay = $(el).data('delay') || TIME

      if (isFade) {
        $(el).siblings().fadeOut(speed)
        setTimeout(() => {
          $(el).fadeIn(speed)

          //window.updateCatalogSizeSliderSlider()
        }, delay)
      } else {
        $(el).show().siblings().hide()
      }
    })
  }
})
