import Form from './form';
import Mustache from 'mustache';

export default class RestorePassword {
  constructor() {
    this.headerSelector = $('.js-restore-password-header')
    this.headerDOM = this.headerSelector.html()

    this.bindEvent()
  }

  renderHeader(data) {
    this.headerSelector.html(Mustache.render(this.headerDOM, data))

    $('.js-restore-password-first-step').css('display', 'none')
    $('.js-submit-first-step').css('display', 'none')

    $('.js-restore-password-second-step').css('display', 'block')
    $('.js-submit-second-step').css('display', 'block')
  }

  handlerEvent(e) {
    let form = new Form(e)
    form.post((response) => {
      this.renderHeader(response.data)
    })
  }

  bindEvent() {
    $('#popupRestorePasswordForm').on('submit', (e) => this.handlerEvent(e))
  }
}
