import { BX_SESSID_RESPONSE_HEADER, BX_META_SESSID_SELECTOR } from './constants'
import App from "./index";

const DATA_TYPE = 'json'

const SUCCESS_STATUS = 'success'
const ERROR_STATUS = 'error'

export default class Form {
  constructor(event) {
    this.selector = $(event.currentTarget)
    this.validator = $(this.selector).validate()
    this.action = $(this.selector).attr('action')
    this.method = $(this.selector).attr('method')
    this.data = $(this.selector).serializeArray()

    this.disableDefaultBehavior(event)

    this.data.push({name: 'sessid', value: $(BX_META_SESSID_SELECTOR).attr('content')})
  }

  disableDefaultBehavior(event) {
    event.preventDefault()
  }

  getAjaxObject() {
    return {
      type: 'post',
      url: this.action,
      data: this.data,
      dataType: DATA_TYPE,
    }
  }

  parseBitrixErrorStructure(errors) {
    const validationObject = {};
    errors.forEach((error) => {
      let field = error.field
      validationObject[field] = error.message
    })
    return validationObject
  }

  showErrors(errors) {
    this.validator.showErrors(this.parseBitrixErrorStructure(errors))
  }

  post(callback) {
    if (this.selector.valid()) {
      $
        .post(this.getAjaxObject())
        .then((response, textStatus, request) => {
          try {
            switch (response.status) {
              case SUCCESS_STATUS:
                callback(response)
                break
              case ERROR_STATUS:
                let sessid = request.getResponseHeader(BX_SESSID_RESPONSE_HEADER) || '';

                if (sessid.length > 0) {
                  App.updateSessid(request.getResponseHeader(BX_SESSID_RESPONSE_HEADER))
                }

                this.showErrors(response.errors)
                break
            }
          } catch (error) {
            console.log(error)
          }
        })
    }
  }
}
