if (window.location.hash) {
  const scrollSection = $(`[data-scroll-section='${window.location.hash}']`)

  if (scrollSection.length) {
    const scrollTop = scrollSection.offset().top

    $('body, html').animate({
      scrollTop: scrollTop - 100,
    })
  }
}
