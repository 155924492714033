import Form from './form';
import Mustache from 'mustache';

export default class PersonalAppealsForm {
  constructor() {
    this.lastAppealSelector = $('.js-last-appeal')
    this.lastAppealDOM = this.lastAppealSelector.html()

    this.bindEvent()
  }

  renderLastAppeal(data) {
    this.lastAppealSelector.html(Mustache.render(this.lastAppealDOM, data))
    this.lastAppealSelector.css('display', 'block')
    $('.js-personalAppealList .js-accordionToggle').eq(0).trigger('click')
    window.hideAppealForm()
  }

  handleEvent(event) {
    let form = new Form(event)
    form.post((response) => {
      this.renderLastAppeal(response.data)
    })
  }

  bindEvent() {
    $('#appealsForm').on('submit', (e) => this.handleEvent(e))
  }
}
