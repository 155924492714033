import { SvgMoveAnimation } from '../SvgMoveAnimation'

// Анимация категорий
if ($('.js-svgMoveCategories').length > 0) {
  new SvgMoveAnimation({
    rootEl: $('.js-svgMoveCategories'),
    resetDistance: [
      {
        distance: 91.6,
        r: 18.5,
      },
      {
        distance: 96.6,
        r: 36.5,
      },
      {
        distance: 100.87,
        r: 2.5,
      },
    ],
    configPoints: {
      0: [
        {
          distance: 100.87,
          r: 2.5,
        },
        {
          distance: 191.6,
          r: 18.5,
        },
        {
          distance: 196.6,
          r: 36.5,
        },
      ],
      1: [
        {
          distance: 96.6,
          r: 36.5,
        },
        {
          distance: 100.87,
          r: 2.5,
        },
        {
          distance: 191.6,
          r: 18.5,
        },
      ],
    },
  })
}

// Анимация стилей
if ($('.js-svgMoveStyle').length > 0) {
  $('.js-svgMoveStyle').each((_, el) => {
    new SvgMoveAnimation({
      startCurrentIndex: 1,
      rootEl: $(el),
      resetDistance: [
        {
          distance: 178.35,
          r: 2.5,
        },
        {
          distance: 93.55,
          r: 2.5,
        },
        {
          distance: 97.45,
          r: 2.5,
        },
      ],
      configPoints: {
        1: [
          {
            distance: 197.45,
            r: 2.5,
          },
          {
            distance: 178.35,
            r: 2.5,
          },
          {
            distance: 193.55,
            r: 2.5,
          },
        ],
        2: [
          {
            distance: 193.55,
            r: 2.5,
          },
          {
            distance: 97.45,
            r: 2.5,
          },
          {
            distance: 178.35,
            r: 2.5,
          },
        ],
      },
    })
  })
}
