$('.js-headerSearchControl').on('click', (e) => {
  e.preventDefault()
  e.stopPropagation()
  $('.header-search').toggleClass('header-search_is_visible')

  if ($('.header-search').hasClass('header-search_is_visible')) {
    setTimeout(() => {
      $('.header-search .form-input').focus()
    }, 500)
  }
})

$('.header-search input').on('click', (e) => {
  e.stopPropagation()
})

$(document).on('click', () => {
  $('.header-search').removeClass('header-search_is_visible')
})
