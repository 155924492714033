/* eslint-disable */

import { renderMapContent } from './render-map-content'
import { centerMapByClusters } from './utils'

export const renderMap = ({ ref }) => {
  const { config } = ref
  setTimeout(() => {
    window.ymaps.ready().then(() => {
      ref.map = new window.ymaps.Map(
        'ymap',
        {
          center: config.map.center,
          zoom: config.map.zoom,
          controls: [],
          margin: 60,
        },
        {
          maxZoom: config.map.maxZoom,
          minZoom: config.map.minZoom,
          yandexMapDisablePoiInteractivity: true,
        },
      )

      const DARK_MAP = 'custom#dark'
      ymaps.layer.storage.add(DARK_MAP, function DarkLayer() {
        return new ymaps.Layer(
          'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&theme=dark&%c&%l&scale={{ scale }}',
        )
      })
  
      ymaps.mapType.storage.add(
        DARK_MAP,
        new ymaps.MapType('Dark Map', [DARK_MAP]),
      )

      ref.map.setType(DARK_MAP)

      renderMapContent({ ref })

      // Тригерим события инициализации карты
      setTimeout(() => {
        centerMapByClusters({ ref })
      })

      window.centerMapByClusters = () => {
        centerMapByClusters({ ref })
      }

      window.setCenterPlacemark = ({ center }) => {
        ref.map.setCenter(center, 18)
      }
    })
  })

  return `
    <div class="map__wrapper">
      <div class="map__container" id='ymap'></div>
    </div>
  `
}
