const body = $('body')

$('.js-menuBurger').on('click', () => {
  if (body.hasClass('is-menu-opened')) {
    body.removeClass('is-menu-opened body-lock')
  } else {
    body.addClass('is-menu-opened body-lock')
  }
})

$('.js-menuClose').on('click', () => {
  body.removeClass('is-menu-opened body-lock')
})

$('.js-menuNavSublistLink').on('click', (e) => {
  e.preventDefault()
  $('.menu-nav').toggleClass('menu-nav_siblist_visible')
})
