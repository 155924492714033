import Form from './form'
import Mustache from 'mustache'

export default class Register {
  constructor() {
    this.checkCodeSelector = $('.js-register-check-code-body')
    this.checkCodeDOM = this.checkCodeSelector.html()

    this.bindEvents()
  }

  renderSecondStepRegisterByPhone(data) {
    this.checkCodeSelector.html(Mustache.render(this.checkCodeDOM, data))
  }

  renderSecondStepRegisterByEmail(data) {
    let emailSecondStep = $('.js-email-second-step')

    emailSecondStep.html(Mustache.render(emailSecondStep.html(), data))
    emailSecondStep.show()

    window.authFormOpen('login-by-email-second-step')
  }

  handleCheckLogin(event) {
    let form = new Form(event)
    form.post((response) => {
      let userLogin = $('.js-regnickname').val()

      $('.js-nicknameInputHidden').val(userLogin)

      window.authFormOpen('register-by-email')
    })
  }

  handleRegisterEmail(event) {
    let form = new Form(event)
    form.post((response) => {
      this.renderSecondStepRegisterByEmail(response.data)
    })
  }

  handleRegisterPhone(event) {
    let form = new Form(event)
    form.post((response) => {
      this.renderSecondStepRegisterByPhone(response.data)
      window.authFormOpen('register-by-code')
    })
  }

  handleCheckCode(event) {
    let form = new Form(event)
    form.post((response) => {
      window.location.href=form.selector.data('success-url')
    })
  }

  bindEvents() {
    $('#registerCheckLoginForm').on('submit', (e) => this.handleCheckLogin(e))
    $('#popupRegisterByEmailForm').on('submit', (e) => this.handleRegisterEmail(e))
    $('#popupRegisterByPhoneForm').on('submit', (e) => this.handleRegisterPhone(e))
    $('#popupRegisterCheckCodeForm').on('submit', (e) => this.handleCheckCode(e))
  }
}
