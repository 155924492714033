import Mustache from 'mustache'

export default class HeaderBasket {
  constructor ($el, basketManager, app) {
    this.DOM = {}
    this.DOM.$el = $el
    this.app = app

    this.data = this.DOM.$el.find('.js-data').length ? $.parseJSON(this.DOM.$el.find('.js-data').html()) : {}
    this.DOM.$basketItems = this.DOM.$el.find('.js-basket-items')
    this.DOM.$basketTotal = this.DOM.$el.find('.js-basket-total')

    this.basketItemsTemplate = this.DOM.$el
      .find('.js-basket-items-template')
      .html()
    this.basketTotalTemplate = this.DOM.$el
      .find('.js-basket-total-template')
      .html()

    this.basketManager = basketManager
    this.basketManager.setData(this.data)

    this.init()
  }

  init() {
    this.render()
    this.bindEvents()
  }

  render(refresh = false) {
    this.renderItems()
    this.renderTotal()

    if (refresh)
    {
      this.app.initNum()
    }
  }

  renderItems()
  {
    this.DOM.$basketItems.html(
      Mustache.render(this.basketItemsTemplate, this.data)
    )
  }

  renderTotal()
  {
    this.DOM.$basketTotal.html(
      Mustache.render(this.basketTotalTemplate, this.data.SUMMARY)
    )
  }

  bindEvents() {
    let _this = this
    this.DOM.$el.on('change', '.js-basket-quantity', e => {
      _this.sendRequest($(e.currentTarget).closest('form'))
    })
  }

  setData (data) {
    this.data = data
  }

  sendRequest(form, additionalData = [], onSuccess = function() {})
  {
    if (this.isAjaxRunning)
    {
      return
    }

    let data = form.serializeArray()

    data.push(
      {
        name: 'sessid',
        value: this.app.sessid
      });

    if (additionalData.length > 0) {
      data = data.concat(additionalData)
    }

    this.isAjaxRunning = true

    $.ajax({
      url: $(form).attr('action'),
      data: data,
      method: $(form).attr('method')
    }).then(response => {
      if (response && response.data)
      {
        this.data = response.data
        this.basketManager.refresh(this.data)

        if (response.status === 'success') {
          onSuccess()
        }
      }
      this.isAjaxRunning = false
    })
  }

}
