import Form from "./form";

$('#popupAuthForm').on('submit', (e) => {
  let form = new Form(e)
  form.post((response) => {
    window.location.href = window.location.href=form.selector.data('success-url')
  })
})

$('#resetPasswordForm').on('submit', (e) => {
  let form = new Form(e)
  form.post((response) => {
    let informBlock = $('.js-informSuccess')
    let submitBlock = $('.js-resetSubmit')

    informBlock.css('display', 'block')
    submitBlock.css('display', 'none');
  })
})

$('#systemAuthForm').on('submit', (e) => {
  let form = new Form(e)
  form.post((response) => {
    window.location.href = window.location.href=form.selector.data('success-url')
  })
})
