import Swiper from 'swiper'
import { Navigation, EffectFade } from 'swiper/modules'

if ($('.js-enteringMobCatSlider').length > 0) {
  new Swiper('.js-enteringMobCatSlider', {
    modules: [EffectFade, Navigation],
    slidesPerView: 1,

    allowTouchMove: false,

    navigation: {
      prevEl: '.js-enteringMobCatSliderPrev',
      nextEl: '.js-enteringMobCatSliderNext',
    },

    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  })
}

