$(document).on('click', '.js-catalogFilterButton', (e) => {
  const currentTarget = $(e.currentTarget)
  const content = $(
    `[data-filter-content="${currentTarget.attr('data-filter-id')}"]`,
  )

  currentTarget.toggleClass('is_opened').siblings().removeClass('is_opened')

  content.show().siblings().hide()

  if (currentTarget.hasClass('is_opened')) {
    $('.js-catalogFilterDropdown').addClass('is_opened')
  } else {
    $('.js-catalogFilterDropdown').removeClass('is_opened')
  }
})
