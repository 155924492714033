$('.js-personalAppealRadio').on('change', (e) => {
  const currentTarget = $(e.currentTarget)

  currentTarget
    .parents('.accordion')
    .find('.accordion__title')
    .text(
      currentTarget.parent().find('.personal-appeal-radios-label__text').text(),
    )

  currentTarget
    .parents('.accordion')
    .find('.js-accordionToggle')
    .trigger('click')
})

$('.js-personalForm input, .js-personalForm textarea').on('change', () => {
  if ($('.js-personalFormInformSuccess').css('display') === 'none') {
    $('.js-personalFormInformEmptyText').hide()
    $('.js-personalFormSubmitBlock').show()
  }
})

$('.js-personalAppealNewOne').on('click', () => {
  window.showAppealForm()
})

window.showAppealForm = () => {
  $('.js-personalAppealForm').show()
  $('.js-personalAppealList').hide()
}

window.hideAppealForm = () => {
  $('.js-personalAppealForm').hide()
  $('.js-personalAppealList').show()
}
