import Inputmask from 'inputmask'

for (const phoneElement of document.querySelectorAll('.js-phone')) {
  const im = new Inputmask({
    mask: '+7 999 999-99-99',
    placeholder: '_',
    showMaskOnHover: true,
  })

  im.mask(phoneElement)
}

for (const dateElement of document.querySelectorAll('.js-dateMask')) {
  const im = new Inputmask({
    mask: '99.99.9999',
    placeholder: '_',
    showMaskOnHover: true,
  })

  im.mask(dateElement)
}
