$(document).on('click', '.js-accordionToggle', (e) => {
  const currentTarget = $(e.currentTarget)

  currentTarget.toggleClass('is_active')

  currentTarget.next().slideToggle(200)
})

window.accordionOpen = (currentEl) => {
  currentEl.addClass('is_active')
  currentEl.next().slideDown(200)
}

window.accordionClose = (currentEl) => {
  currentEl.removeClass('is_active')
  currentEl.next().slideUp(200)
}
